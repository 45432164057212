import React from 'react'
import Layout from 'Components/Layout'
import PropTypes from 'prop-types'
import schoolService from 'Services/schoolService'
import RedirectToAppPage from 'Utils/RedirectToAppPage'
import SchoolLandingPage from 'Components/SchoolLandingPage'
import InitialPropsHelper from 'Utils/InitialPropsHelper'
import PageHead from 'Components/PageHead'
import GetGlobals from 'Utils/GetGlobals'

const LandingPage = ({
  schoolLandingPageEnabled,
  landingPageData,
  globals,
}) => {
  // if custom school landing page enabled, return landing page data

  const { school } = globals

  if (schoolLandingPageEnabled) {
    return (
      <div>
        <PageHead
          title={school.name}
          description={`Explore online courses offered by ${school.name}`}
          school={school}
        />
        <SchoolLandingPage data={landingPageData} />
      </div>
    )
  }

  // will never reach this point
  return <div />
}

LandingPage.getInitialProps = async ({ res, req }) => {
  let landingPageData = null
  let schoolLandingPageEnabled = false
  let customTopNav = []
  const data = await schoolService.getLandingPageData()
  schoolLandingPageEnabled = data.landingPageEnabled
  if (!schoolLandingPageEnabled) {
    // if school page but landing page not enabled redirect to '/courses'
    RedirectToAppPage(res, '/courses')
  }
  landingPageData = data.landingPageData
  customTopNav = data.landingPageData.topNav
  return InitialPropsHelper({
    globals: GetGlobals(req),
    landingPageData,
    schoolLandingPageEnabled,
    customTopNav,
  })
}

LandingPage.defaultProps = {
  landingPageData: null,
}

LandingPage.propTypes = {
  schoolLandingPageEnabled: PropTypes.bool.isRequired,
  landingPageData: PropTypes.object,
  globals: PropTypes.object.isRequired,
}

export default Layout(LandingPage, {
  sidebarVariant: 'LANDING',
  isFooterPresent: true,
  fullWidth: true,
  noPadding: true,
})
