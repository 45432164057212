import Router from 'next/router'

const RedirectToAppPage = (res, relativeUrl) => {
  if (res) {
    res.writeHead(302, {
      Location: relativeUrl,
    })
    res.end()
  } else {
    Router.push(relativeUrl)
  }
}

export default RedirectToAppPage
